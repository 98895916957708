import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardCompanies from "../pages/dashboard/DashboardCompanies";
import DashboardDoors from "../pages/dashboard/DashboardDoors";
import DashboardHome from "../pages/dashboard/DashboardHome";
import DashboardModules from "../pages/dashboard/DashboardModules";
import DashboardOrders from "../pages/dashboard/DashboardOrders";

const DashboardRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<DashboardHome />} />
      <Route path="/orders" element={<DashboardOrders />} />
      <Route path="/companies" element={<DashboardCompanies />} />
      <Route path="/modules" element={<DashboardModules />} />
      <Route path="/doors" element={<DashboardDoors />} />
    </Routes>
  );
};

export default DashboardRouter;
