import { HiBadgeCheck } from "react-icons/hi";
import { TiWarning } from "react-icons/ti";
import { TiDelete } from "react-icons/ti";
const ConfirmationModal = (props: any) => {
  const { open, type, headerText, contentText, onConfirm, onCancel } = props;

  const styles: any = {
    backdrop: {
      width: "100%",
      height: "100vh",
      position: "absolute",
      backgroundColor: "rgb(0 0 0 / 61%)",
      left: 0,
      top: 0,
      zIndex: 1000,
      color: "#3e3e3e",
    },
    main: {
      background: "#f3f3f3",
      borderRadius: "3px",
      maxWidth: "65%",
    },
    header: {},
  };

  const Warning = () => (
    <TiWarning style={{ color: "orange", fontSize: "30px" }} />
  );
  const Danger = () => <TiDelete style={{ color: "red", fontSize: "30px" }} />;
  const Success = () => (
    <HiBadgeCheck style={{ color: "green", fontSize: "30px" }} />
  );

  const Types: any = {
    undefined: "",
    success: <Success />,
    danger: <Danger />,
    warning: <Warning />,
  };

  return (
    <div
      className={`${
        open ? "d-flex" : "d-none"
      } justify-content-center align-items-center`}
      style={styles.backdrop}
    >
      <div className="row p-3" style={styles.main}>
        <div className="size-09 bold">
          {Types[type]} {headerText || "Confirmacion"}
        </div>
        <div className="py-3">
          {contentText || "¿Está seguro de que desea realizar esta acción?"}
        </div>
        <div className="text-end">
          <button className="btn btn-outline-danger me-3" onClick={onCancel}>
            Cancelar
          </button>
          <button className="btn btn-outline-secondary" onClick={onConfirm}>
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
