/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import LiderRouter from "./LiderRouter";
import PickupBoxRouter from "./PickupBoxRouter";
import PickupRouter from "./PickupRouter";
import ResidentialRouter from "./ResidentialRouter";
import UnimarcRouter from "./UnimarcRouter";
import LasCondesRouter from "./LasCondesRouter";
import ResidentialCuboRouter from "./ResidentialCuboRouter";

/**
 * 
 * @modules
 * 
 * residential-module: Modulo de entrega residencial.
 *    Primero se crea un usuario, y este usuario queda disponible para ser usado en el modulo de entrega residencial.
 * 
 * pickup-module: Modulo de entrega con caja reservada
 *    Al momento de crear la orden, se inicia una reserva, crea el usuario y hace la reserva de la(s) caja(s).
 * 
 * pickup-box-module: Modulo de entrega sin caja reservada
 *    Al momento de crear la orden, se inicia una reserva, crea el usuario, pero no hace la reserva de una o mas cajas.
 *    Se selecciona la caja al momento de hacer la entrega
 * 
 * lider-module: 
 *    Mismo que modulo `pickup-module`, pero con la diferencia que tiene el template de lider.
 * 
 * unimarc-module:
 *    Mismo que modulo `pickup-module`, pero con la diferencia que tiene el template de unimarc.
 * 
 * lascondes-module:
 *    Mismo que modulo `pickup-module`, pero con la diferencia que tiene el template de la municipalidad las condes.
 */

const RouterWrapper = () => {
  const Routers: any = {
    "residential-module": ResidentialRouter,
    "pickup-module": PickupRouter,
    "pickup-box-module": PickupBoxRouter,
    "lider-module": LiderRouter,
    "unimarc-module": UnimarcRouter,
    "lascondes-module": LasCondesRouter,
    "residential-cubo-module": ResidentialCuboRouter,
  };

  const { modules } = useSelector((state: any) => state);

  const [state, setState] = useState({
    Router: Routers[modules.module_selected],
  });

  useEffect(() => {
    const Router = Routers[modules.module_selected];
    if (Router) {
      setState({ ...state, Router });
    }
  }, [modules.module_selected]);

  return (
    <>
      <Loader />
      <state.Router />
    </>
  );
};

export default RouterWrapper;
