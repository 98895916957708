/* eslint-disable react-hooks/exhaustive-deps */
import Logo from "../../../../components/Logo";
import Header from "../../components/Header";
import { ReactComponent as Delivery } from "../../../../assets/svg/package-box.svg";
import { ReactComponent as Pickup } from "../../../../assets/svg/package.svg";
import ItemAction from "../../components/ItemAction";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { set_loader } from "../../../../redux/actions/loader";

const Home = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch()
  useEffect(() => {
    const timeout:any = setTimeout(() => {
      dispatch(set_loader({ is_loading: false }))
      console.log('CLOSE LOADER')
    }, 5000)

    return () => {
      clearInterval(timeout)
      console.log('CLEAR INTERVAL')
    }
  }, [])
  
  return (
    <div className="container-fluid h-100">
      <Header />
      <div className="content-section">
        <div className="row align-items-center justify-content-center my-5">
          <div className="col-6">
            <Logo />
          </div>
        </div>
        <div className="row align-items-center justify-content-center mt-5">
          <ItemAction
            label="Carga"
            Image={Delivery}
            description="Haga click aquí para hacer una carga de un pedido."
            handleOnClick={() => navigate("/delivery", { replace: true })}
          />
          <ItemAction
            label="Retiro"
            Image={Pickup}
            description="Haga click aquí para hacer un retiro de un pedido."
            handleOnClick={() => navigate("/pickup", { replace: true })}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
