export const com_list:any = [
    {
        label: 'COM1',
        value: 'COM1'
    },
    {
        label: 'COM2',
        value: 'COM2'
    },
    {
        label: 'COM3',
        value: 'COM3'
    },
    {
        label: 'COM4',
        value: 'COM4'
    },
    {
        label: 'COM5',
        value: 'COM5'
    },
    {
        label: 'COM6',
        value: 'COM6'
    },
    {
        label: 'COM7',
        value: 'COM7'
    },
    {
        label: 'COM8',
        value: 'COM8'
    },
    {
        label: 'COM9',
        value: 'COM9'
    },
    {
        label: 'COM10',
        value: 'COM10'
    },
];