export const com_list:any = [
    {
        label: 'COM1',
        value: 'COM1'
    },
    {
        label: 'COM2',
        value: 'COM2'
    },
    {
        label: 'COM3',
        value: 'COM3'
    },
    {
        label: 'COM4',
        value: 'COM4'
    },
    {
        label: 'COM5',
        value: 'COM5'
    },
    {
        label: 'COM6',
        value: 'COM6'
    },
    {
        label: 'COM7',
        value: 'COM7'
    },
    {
        label: 'COM8',
        value: 'COM8'
    },
    {
        label: 'COM9',
        value: 'COM9'
    },
    {
        label: 'COM10',
        value: 'COM10'
    },
];

export const number_list:any = [
    {
        label: '0',
        value: '0'
    },
    {
        label: '1',
        value: '1'
    },
    {
        label: '2',
        value: '2'
    },
    {
        label: '3',
        value: '3'
    },
    {
        label: '4',
        value: '4'
    },
    {
        label: '5',
        value: '5'
    },
    {
        label: '6',
        value: '6'
    },
    {
        label: '7',
        value: '7'
    },
    {
        label: '8',
        value: '8'
    },
    {
        label: '9',
        value: '9'
    },
    {
        label: '10',
        value: '10'
    },
    {
        label: '11',
        value: '11'
    },
    {
        label: '12',
        value: '12'
    },
    {
        label: '13',
        value: '13'
    },
    {
        label: '14',
        value: '14'
    },
    {
        label: '15',
        value: '15'
    },
];