import Header from "../../components/Header";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import BoxToOpenDelivery from "../../../../components/BoxToOpenDelivery";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import OrderService from "../../../../services/order.service";
import { _handleError } from "../../../../helpers/errors";

const OpenBoxDelivery = () => {
  const [state, setState] = useState({
    data: "",
    modal: { headerText: "", contentText: "", open: false, type: "warning" },
  });
  const { delivery } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const Order = new OrderService();

  const _handleNext = () => {
    setState({
      ...state,
      modal: {
        ...state.modal,
        contentText: `¿Estas seguro que desea finalizar esta transáccion?`,
        open: true,
      },
    });
  };

  const _handleConfirmModal = async () => {
    const { boxes, reservation } = delivery;
    try {
      dispatch(set_loader({ is_loading: true }));
      await Order.deliveryOrder({
        id: reservation.id,
        reservation_type: "pickup_delivered",
        boxes: boxes.filter((item: any) => item.opened),
      });
      dispatch(set_loader({ is_loading: true }));
      navigate("/success");
      dispatch(set_loader({ is_loading: false }));
    } catch (e: any) {
      _handleError(
        e,
        "No se puede completar el retiro. Encuentra una persona responsable que te informe sobre tu problema."
      );
      dispatch(set_loader({ is_loading: false }));
    }
  };

  const _handleCancelModal = () => {
    setState({
      ...state,
      modal: {
        ...state.modal,
        open: false,
      },
    });
  };

  return (
    <div className="container-fluid h-100">
      <ConfirmationModal
        {...state.modal}
        onConfirm={_handleConfirmModal}
        onCancel={_handleCancelModal}
      />
      <Header />
      <div className="content-section px-4 d-flex flex-column justify-content-around">
        <BoxToOpenDelivery module="pickup" action="delivery" />
        <div className="row">
          <div className="col-12 text-end px-0">
            <button
              className="me-4 px-4 py-2 border-0 main-button bold"
              onClick={() => navigate("/", { replace: true })}
            >
              Cancelar
            </button>
            <button
              className="px-4 py-2 border-0 main-button bold"
              onClick={_handleNext}
            >
              Finalizar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenBoxDelivery;
