const ItemAction = (props: any) => {
  const { label, Image, description, handleOnClick } = props;

  const _handleOnClick = () => {
    handleOnClick();
  };

  return (
    <div className="mx-3 py-3 box-button shadow" onClick={_handleOnClick}>
      <div className="box-button__image">
        <div className="box-button__image__content">
          <Image />
        </div>
      </div>
      <div className="box-button__label mt-3">
        {label}
        <hr className="my-2" />
        <div className="box-button__label__desc mt-4">{description}</div>
      </div>
    </div>
  );
};

export default ItemAction;
