/* eslint-disable react-hooks/exhaustive-deps */
import Logo from "../../../../components/Logo";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import Clock from "../../../../components/Clock";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { set_loader } from "../../../../redux/actions/loader";

const Home = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch()
  useEffect(() => {
    const timeout:any = setTimeout(() => {
      dispatch(set_loader({ is_loading: false }))
      console.log('CLOSE LOADER')
    }, 5000)

    return () => {
      clearInterval(timeout)
      console.log('CLEAR INTERVAL')
    }
  }, [])
  
  return (
    <div className="container-fluid h-100">
      <Header />
      <div className="content-section">
        <div className="row align-items-center justify-content-center my-5">
          <div className="col-8 mt-5">
            <Logo company="lider" />
          </div>
          <div className="col-12 text-center" style={{ fontSize: "3rem" }}>
            Autoservicios / Lockers
          </div>
        </div>
        <div className="row align-items-center justify-content-center mt-5">
          <div className="col-12 text-center mt-3">
            <button
              className="button-home-pickup"
              onClick={() => navigate("/pickup")}
            >
              Retirar Pedido
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12 my-5 d-flex justify-content-end pe-5">
            <Clock />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
