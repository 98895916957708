/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Button, Notification, SelectPicker, toaster } from "rsuite";
import MasterKeyAuth from "../../../components/MasterKeyAuth";
import DashboardTemplate from "../DashboardTemplate";

const DashboardCompanies = () => {
  const [masterKeyModal, setMasterKeyModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [companyList, setCompanyList] = useState<any>([]);
  const [moduleSelected, setModuleSelected] = useState<any>("");

  const _handleChangeModule = () => {
    toaster.push(message, { placement: "topEnd" });
  };

  const message = (
    <Notification
      type={"info"}
      header={"info"}
      closable
      style={{ color: "#000" }}
    >
      Empresa cambiada com éxito...
    </Notification>
  );

  return (
    <DashboardTemplate>
      <MasterKeyAuth
        open={masterKeyModal}
        title="Informe su master key"
        handleClose={() => setMasterKeyModal(false)}
        handleConfirm={() => setMasterKeyModal(false)}
      />

      <div className="row px-4 py-2 mx-0 mb-4">
        <div
          className="col-12 my-2 pb-2 size-12"
          style={{ borderBottom: "2px solid #afafaf", fontWeight: "bold" }}
        >
          Empresas
        </div>
      </div>

      <div className="row background-color-white shadow-sm px-3 py-5 mx-3 mt-3 rounded">
        <div className="col-12 px-5 size-09 mb-1" style={{ fontWeight: "500" }}>
          Selecciona una empresa
        </div>
        <div className="col-12 px-5 ">
          <SelectPicker
            data={companyList}
            value={moduleSelected}
            className="w-100"
            placeholder="Seleciona una empresa"
            onChange={(value: any) => setModuleSelected(value)}
            locale={{
              searchPlaceholder: "Buscar",
              emptyMessage: "no encontramos ninguna empresa",
              noResultsText: "no encontramos ninguna empresa",
            }}
          />
        </div>

        <div className="col-12 text-end px-5 mt-5">
          <Button onClick={_handleChangeModule} appearance="primary">
            Guardar
          </Button>
        </div>
      </div>
    </DashboardTemplate>
  );
};

export default DashboardCompanies;
