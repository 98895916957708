import Image from "../Image";
import errorIcon from "../../assets/svg/error-image.svg";
const CompanyCard = (props: any) => {
  const { onClick, name, logo, id, selected } = props;

  const styles = {
    card: {
      borderRadius: "7px",
      maxWidth: "102px",
      minWidth: "100px",
      height: "145px"
    },
    imageWrapper: {
      backgroundColor: "rgb(255 255 160)",
      borderRadius: "50%",
      width: "45px",
      height: "45px",
    },
  };

  return (
    <div
      className="col mx-2 px-3 pb-2 pt-3 my-2 shadow d-flex flex-column justify-content-between"
      style={{
        ...styles.card,
        backgroundColor: selected ? "#f3f3f3" : "#fefefe",
        border: selected ? "2px solid #000" : "none",
      }}
      onClick={() => onClick(id)}
    >
      <div className="d-flex justify-content-center align-items-center size-09">
        <div
          className="size-09 bold text-center"
          style={{ color: selected ? "#000" : "#909090" }}
        >
          {name}
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center my-2">
        <Image
          src={logo || errorIcon}
          style={{
            width: "90%",
            maxHeight: "70px",
            objectFit: "fill",
          }}
        />
      </div>
    </div>
  );
};

export default CompanyCard;
