const Input = (props: any) => {
  const { label, onChange, value } = props;

  const styles: any = {
    main: {
      borderRadius: "5px",
      position: "relative",
      overflow: "hidden",
    },
    label: {
      backgroundColor: "#fff",
      textTransform: "uppercase",
      height: "45px",
    },
    htmlElement: {
      width: "100%",
      backgroundColor: "#000",
      border: "none",
      color: "#FFF",
      textTransform: "uppercase",
      minHeight: "100%",
    },
  };

  return (
    <div className="w-100 d-flex my-2" style={styles.main}>
      <div
        className="col-3 d-flex align-items-center ps-3"
        style={styles.label}
      >
        {label}
      </div>
      <div className="col-9 p-0 m-0">
        <input
          className="px-3"
          style={styles.htmlElement}
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
};

export default Input;
