/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CompanyCard from "../../../../components/CompanyCard";

const Companies = () => {
  const [state, setState] = useState({
    companies: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    const companies: any = [
      {
        id: 0,
        name: "Gux",
        logo: "https://elock.cl/wp-content/uploads/2020/08/Logo.png",
        selected: false,
      },
      {
        id: 1,
        name: "Elock",
        logo: "https://gux.tech/wp-content/uploads/2019/10/logo-gux.png",
        selected: false,
      },
    ];
    Array.from({ length: 3 }).forEach((_: any, index: any) => {
      companies.push({
        id: index + 2,
        name: `Company ${index + 1}`,
        selected: false,
      });
    });

    setState({ ...state, companies });
  }, []);

  const _handleSelectCompany = (id: any) => {
    const companies: any = state.companies.map((item: any) => {
      if (item.id === id) {
        item.selected = true;
      } else {
        item.selected = false;
      }

      return item;
    });
    setState({ ...state, companies });
  };
  return (
    <div className="container-fluid h-100">
      <Header />
      <div className="content-section px-4 d-flex flex-column justify-content-around">
        <div
          className="row box px-3 py-5"
          style={{ maxHeight: "65vh", overflow: "auto" }}
        >
          <div
            className="col-12 mb-3 size-14 bold text-center"
            style={{ textTransform: "uppercase" }}
          >
            Seleccione una empresa
          </div>
          {state.companies &&
            state.companies.map((company: any) => (
              <CompanyCard
                {...company}
                onClick={_handleSelectCompany}
                key={company.id}
              />
            ))}
        </div>

        <div className="row mt-3">
          <div className="col-12 text-end px-0">
            <button
              className="me-4 px-4 py-2 border-0 main-button bold"
              onClick={() => navigate("/", { replace: true })}
            >
              Cancelar
            </button>
            <button
              className="px-4 py-2 border-0 main-button bold"
              onClick={() => navigate("/open-box-delivery", { replace: true })}
            >
              Proximo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Companies;
