import Image from "../Image";
import boxImage from "../../assets/svg/box.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { set_order } from "../../redux/actions/order";
import { set_pickup } from "../../redux/actions/pickup";
import DoorLockerService from "../../services/door_locker.service";
import { _handleError } from "../../helpers/errors";
import { set_delivery } from "../../redux/actions/delivery";
const CardOpenBox = (props: any) => {
  const { box_type, door_number, data, action } = props;
  const { order, pickup, delivery, session } = useSelector(
    (state: any) => state
  );
  const dispatch: any = useDispatch();
  const DoorLocker = new DoorLockerService();
  const styles = {
    card: {
      backgroundColor: "#FFF",
      borderRadius: "7px",
      maxWidth: "270px",
      minWidth: "250px",
    },
    imageWrapper: {
      backgroundColor: "rgb(255 255 160)",
      borderRadius: "50%",
      width: "45px",
      height: "45px",
    },
  };

  const _handleOpenBox = async () => {
    try {
      switch (action) {
        case "pickup":
          await _handleOpenBoxPickup();
          break;
        case "delivery":
          await _handleOpenBoxDelivery();
          break;
        default:
          await _handleOpenBoxDefault();
      }
    } catch (e: any) {
      _handleError(e, e.message);
    }
  };

  const _handleOpenBoxPickup = async () => {
    const { boxes: boxesPickup } = pickup;
    const boxPickup: any = boxesPickup.find((item: any) => item.id === data.id);
    const payloadPickup: any = {
      com: session?.profile?.door_com_number,
      driveboard: boxPickup.desk_number,
      door: boxPickup.desk_door_number,
      box: boxPickup
    };
    const responsePickup = await DoorLocker.openDoor(payloadPickup);
    if (responsePickup.data.status === "error") {
      throw new Error("No fue posible abrir esta puerta.");
    }
    boxPickup.opened = true;
    dispatch(set_pickup({ boxes: boxesPickup }));
  };

  const _handleOpenBoxDelivery = async () => {
    const { boxes: boxesDelivery } = delivery;
    const boxDelivery: any = boxesDelivery.find(
      (item: any) => item.id === data.id
    );
    const payloadDelivery: any = {
      com: session?.profile?.door_com_number,
      driveboard: boxDelivery.desk_number,
      door: boxDelivery.desk_door_number,
      box: boxDelivery
    };
    const responseDelivery = await DoorLocker.openDoor(payloadDelivery);
    if (responseDelivery.data.status === "error") {
      throw new Error("No fue posible abrir esta puerta.");
    }
    boxDelivery.opened = true;
    dispatch(set_delivery({ boxes: boxesDelivery }));
  };

  const _handleOpenBoxDefault = async () => {
    const { boxes } = order;
    const box: any = boxes.find((item: any) => item.id === data.id);
    const payload: any = {
      com: session?.profile?.door_com_number,
      driveboard: box.desk_number,
      door: box.desk_door_number,
      box: box
    };

    const response = await DoorLocker.openDoor(payload);
    if (response.data.status === "error") {
      throw new Error("No fue posible abrir esta puerta.");
    }
    box.opened = true;
    dispatch(set_order({ boxes }));
  };

  return (
    <div
      className={`p-2 col mx-2 my-3 shadow ${
        data?.opened ? "border border-3 border-warning" : "border border-3 border-dark"
      }`}
      style={styles.card}
    >
      <div className="d-flex justify-content-between px-2 mt-2">
        <div>
          <div className="size-13 bold">Caja {door_number}</div>
          <div className="size-08 ms-1">
            {box_type?.name} {data?.opened ? ` - Abierto` : ""}
          </div>
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={styles.imageWrapper}
        >
          <Image src={boxImage} style={{ width: "30px" }} />
        </div>
      </div>

      <div className="row mt-3 px-5 mb-3">
        <button
          className="me-4 px-4 py-2 border-0 main-button bold shadow"
          onClick={_handleOpenBox}
        >
          ABRIR
        </button>
      </div>
    </div>
  );
};

export default CardOpenBox;
