/* eslint-disable react-hooks/exhaustive-deps */
import Logo from "../../../components/Logo";
import ItemMenu from "./ItemMenu";
import { ReactComponent as Locker } from "../../../assets/svg/locker.svg";
import { ReactComponent as Box } from "../../../assets/svg/locker.svg";
import { ReactComponent as Orders } from "../../../assets/svg/package.svg";
// import { ReactComponent as Companies } from "../../../assets/svg/company-lines.svg";
import { ReactComponent as Modules } from "../../../assets/svg/module.svg";
import { ReactComponent as LogOut } from "../../../assets/svg/logout.svg";
import { ReactComponent as Coms } from "../../../assets/svg/coms.svg";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { set_session } from "../../../redux/actions/session";
import { useSelector } from "react-redux";

const DashboardTemplate = (props: any) => {
  const { children } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { session } = useSelector((state: any) => state);
  const [state, setState] = useState({
    menu: [
      {
        label: "Cajas",
        url: "/dashboard",
        Icon: Box,
        selected: false,
      },
      {
        label: "Pedidos",
        url: "/dashboard/orders",
        Icon: Orders,
        selected: false,
      },
      // {
      //   label: "Empresa",
      //   url: "/dashboard/companies",
      //   Icon: Companies,
      //   selected: false,
      // },
      {
        label: "Modulos",
        url: "/dashboard/modules",
        Icon: Modules,
        selected: false,
      },
      {
        label: "Puertos",
        url: "/dashboard/doors",
        Icon: Coms,
        selected: false,
      },
    ],
  });

  const dispatch: any = useDispatch();

  useEffect(() => {
    state.menu.map((menu: any) => {
      if (menu.url === location.pathname) {
        menu.selected = true;
      } else {
        menu.selected = false;
      }
      return menu;
    });

    setState({ ...state });
  }, [location]);

  const _handleLogout = () => {
    dispatch(set_session({ ...session, is_logged: false }));
    navigate("/");
  };

  return (
    <div className="container-fluid h-100 background-color-base">
      <div className="row h-100 color-black">
        <div
          className=" h-100 background-color-white shadow-sm d-flex flex-column align-items-center p-0"
          style={{ width: "11%" }}
        >
          <div className="w-100 px-3 pt-3 pb-2 text-center">
            <Logo company="gux" />
          </div>

          {state.menu &&
            state.menu.map((menu: any) => (
              <ItemMenu {...menu} key={menu.label} />
            ))}
          <ItemMenu
            label="Cerrar"
            url="dashboard/exit"
            Icon={LogOut}
            onClick={_handleLogout}
          />
        </div>
        <div className="h-100 m-0 p-0" style={{ width: "89%" }}>
          <div
            className="w-100 background-color-gris shadow-sm px-3 size-09 d-flex justify-content-end align-items-center"
            style={{
              fontWeight: "bold",
              height: "8vh",
              borderBottom: "1px solid #f3f3f3",
            }}
          >
            {session?.profile?.username}
            <Locker
              style={{ width: "30px", height: "30px" }}
              className="ms-3"
            />
          </div>
          <div
            className="content w-100"
            style={{ height: "92vh", overflow: "auto" }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardTemplate;
