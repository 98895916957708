// import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { set_session } from "../../../../redux/actions/session";

import "./component.header.scss";
import Clock from "../../../../components/Clock";

const Header = () => {
  const { session } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const _handleSigIn = () => {
    dispatch(set_session({ ...session, sign_in_component: true }));
  };
  return (
    <div className="row component-header mx-5 mt-4">
      <div
        className="position-absolute w-auto"
        style={{ color: "#000", left: "5%", fontSize:'28px', top:'30px' }}
      >
        <Clock />
      </div>
      <div className="component-header__logo" onClick={_handleSigIn}/>
    </div>
  );
};

export default Header;
