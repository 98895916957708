import Image from "../Image";
import boxImage from "../../assets/svg/box.svg";
import QuantitySelect from "../QuantitySelect";
const CardWithSelect = ({
  id,
  name,
  quantity,
  quantity_selected,
  width,
  height,
  onPlus,
  onSubtract,
  boxStyle = {},
}: any) => {

  const styles = {
    card: {
      backgroundColor: "#FFF",
      borderRadius: "7px",
      maxWidth: "270px",
      minWidth: "250px",
    },
    imageWrapper: {
      backgroundColor: "rgb(255 255 160)",
      borderRadius: "50%",
      width: "45px",
      height: "45px",
    },
  };
  return (
    <div className="p-2 col mx-2 my-3" style={{...styles.card, ...boxStyle}}>
      <div className="d-flex justify-content-between px-2 mt-2">
        <div>
          <div className="size-09">{name}</div>
          <div className="size-13 text-center bold">
            {quantity} <span className="size-07">max</span>
          </div>
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={styles.imageWrapper}
        >
          <Image src={boxImage} style={{ width: "30px" }} />
        </div>
      </div>
      <div
        className="d-flex justify-content-around size-06 bold mt-2"
        style={{ color: "#909090" }}
      >
        <div className="me-3">Ancho: {width / 100} cm</div>
        <div>Altura: {height / 100} cm</div>
      </div>
      <div className="row justify-content-center mt-3">
        <QuantitySelect
          quantity={quantity_selected}
          onAdd={() => onPlus(id)}
          onRemove={() => onSubtract(id)}
        />
      </div>
    </div>
  );
};

export default CardWithSelect;
