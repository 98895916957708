import { createAction } from "redux-actions";

export const SET_MODULES: any = createAction("SET_MODULES");
export const CLEAR_MODULES: any = createAction("CLEAR_MODULES");
export const RELOAD_MODULES: any = createAction("RELOAD_MODULES");
export const SAVE_STATE: any = createAction("SAVE_STATE");

export const set_modules = (payload: any) => {
  return async (dispatch: any) => {
    await dispatch(SET_MODULES(payload));
    await dispatch(SAVE_STATE());
    return Promise.resolve();
  };
};

export const reload_modules = () => {
  return async (dispatch: any) => {
    await dispatch(RELOAD_MODULES());
    await dispatch(SAVE_STATE());
    return Promise.resolve();
  };
};

export const clear_modules = () => {
  return (dispatch: any) => {
    dispatch(CLEAR_MODULES());
    dispatch(SAVE_STATE());
  };
};
