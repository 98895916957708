import Header from "../../components/Header";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import AvailableBoxesWithSelect from "../../../../components/AvailableBoxesWithSelect";

const Boxes = () => {
  const [state, setState] = useState({
    data: "",
    modal: { headerText: "", contentText: "", open: false, type: "warning" },
  });
  const navigate = useNavigate();

  const _handleNext = () => {
    setState({
      ...state,
      modal: {
        ...state.modal,
        contentText: `¿Estas seguro que necesita solo estas cajas?`,
        open: true,
      },
    });
  };

  const _handleConfirmModal = () => {
    navigate("/companies");
  };

  const _handleCancelModal = () => {
    setState({
      ...state,
      modal: {
        ...state.modal,
        open: false,
      },
    });
  };

  return (
    <div className="container-fluid h-100">
      <ConfirmationModal
        {...state.modal}
        onConfirm={_handleConfirmModal}
        onCancel={_handleCancelModal}
      />
      <Header />
      <div className="content-section px-4 d-flex flex-column justify-content-around">
        <AvailableBoxesWithSelect />
        <div className="row">
          <div className="col-12 text-end px-0">
            <button
              className="me-4 px-4 py-2 border-0 main-button bold"
              onClick={() => navigate("/", { replace: true })}
            >
              Cancelar
            </button>
            <button
              className="px-4 py-2 border-0 main-button bold"
              onClick={_handleNext}
            >
              Proximo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boxes;
