import Header from "../../components/Header";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import BoxToOpenDelivery from "../../../../components/BoxToOpenDelivery";
import { useSelector } from "react-redux";
import { _pushToastMessage } from "../../../../helpers/messages";
import { _handleError } from "../../../../helpers/errors";
import OrderService from "../../../../services/order.service";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";

const OpenBoxDelivery = () => {
  const [state, setState] = useState({
    data: "",
    modal: { headerText: "", contentText: "", open: false, type: "warning" },
    modal_cancel: { headerText: "", contentText: "", open: false, type: "warning" },
  });
  const { order } = useSelector((state: any) => state);
  const navigate = useNavigate();
  const Order = new OrderService();
  const dispatch: any = useDispatch();

  const _handleNext = () => {
    setState({
      ...state,
      modal: {
        ...state.modal,
        contentText: `¿Estas seguro que desea finalizar esta transáccion?`,
        open: true,
      },
    });
  };

  const _handleCancelOrderModal = () => {
    setState({
      ...state,
      modal_cancel: {
        ...state.modal_cancel,
        contentText: `¿Estas seguro que quieres cancelar esta entrega?`,
        open: true,
      },
    });
  };

  const _handleConfirmModal = async () => {
    try {
      const boxOpened: any = order.boxes?.filter((item: any) => item.opened);

      if (boxOpened.length === 0) {
        _pushToastMessage({
          type: "error",
          text: "Debe abrir al menos una caja antes de continuar.",
          header: "Aviso",
        });
        _handleCancelModal();
        return;
      }

      order.boxes = boxOpened;

      dispatch(set_loader({ is_loading: true }));

      await Order.confirmOrder(order);
      dispatch(set_loader({ is_loading: false }));
      navigate("/success");
    } catch (e: any) {
      _handleError(e, e.message);
      dispatch(set_loader({ is_loading: false }));
    }
  };

  const _handleCancelModal = () => {
    setState({
      ...state,
      modal: {
        ...state.modal,
        open: false,
      },
      modal_cancel: {
        ...state.modal_cancel,
        open: false,
      },
    });
  };

  const _handleCancelOrder = async () => {
    try {
      dispatch(set_loader({ is_loading: true }));
      await Order.cancelOrder({ id: order.order?.id });
      dispatch(set_loader({ is_loading: false }));
      navigate("/");
    } catch (e: any) {
      _handleError(e, "No fue posible cancelar. ");
      dispatch(set_loader({ is_loading: false }));
    }
  };

  return (
    <div className="container-fluid h-100">
      <ConfirmationModal
        {...state.modal}
        onConfirm={_handleConfirmModal}
        onCancel={_handleCancelModal}
      />

      <ConfirmationModal
        {...state.modal_cancel}
        onConfirm={_handleCancelOrder}
        onCancel={_handleCancelModal}
      />
      <Header />
      <div className="content-section px-4 d-flex flex-column justify-content-around">
        <BoxToOpenDelivery module="residential" action="residential" />
        <div className="row">
          <div className="col-12 text-end px-0">
            <button
              className="me-4 px-4 py-2 border-0 main-button bold"
              onClick={_handleCancelOrderModal}
            >
              Cancelar
            </button>
            <button
              className="px-4 py-2 border-0 main-button bold"
              onClick={_handleNext}
            >
              Finalizar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenBoxDelivery;
