import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "../components/SignIn";
import Boxes from "../modules/ResidentialCubo/pages/Boxes";
import Companies from "../modules/ResidentialCubo/pages/Companies";
import Delivery from "../modules/ResidentialCubo/pages/Delivery";
import Home from "../modules/ResidentialCubo/pages/Home";
import OpenBoxDelivery from "../modules/ResidentialCubo/pages/OpenBoxDelivery";
import OpenBoxDeliveryServices from "../modules/ResidentialCubo/pages/OpenBoxDeliveryServices";
import OpenBoxPickup from "../modules/ResidentialCubo/pages/OpenBoxPickup";
import Pickup from "../modules/ResidentialCubo/pages/Pickup";
import Services from "../modules/ResidentialCubo/pages/Services";
import Success from "../modules/ResidentialCubo/pages/Success";
import DashboardRouter from "./DashboardRouter";

export default function ResidentialCuboRouter() {
  const { session } = useSelector((state: any) => state);
  useEffect(() => {
    import("../modules/ResidentialCubo/styles/_global_residential_cubo.scss");
  }, []);
  return (
    <BrowserRouter>
      <SignIn open={!session.is_connected || session.sign_in_component} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/available-boxes" element={<Boxes />} />
        <Route path="/companies" element={<Companies />} />
        <Route path="/open-box-delivery" element={<OpenBoxDelivery />} />
        <Route path="/services/open-box-delivery-services" element={<OpenBoxDeliveryServices />} />
        <Route path="/open-box-pickup" element={<OpenBoxPickup />} />
        <Route path="/success" element={<Success />} />
        <Route path="/pickup" element={<Pickup />} />
        <Route path="/services" element={<Services />} />
        <Route path="/dashboard/*" element={<DashboardRouter />} />
      </Routes>
    </BrowserRouter>
  );
}
