import Image from "../Image";
import boxImage from "../../assets/svg/box.svg";
const Card = (props: any) => {
  const { name, quantity, width, height } = props;

  const styles = {
    card: {
      backgroundColor: "#FFF",
      width: "200px",
      borderRadius: "7px",
    },
    imageWrapper: {
      backgroundColor: "rgb(255 255 160)",
      borderRadius: "50%",
      width: "45px",
      height: "45px",
    },
  };
  return (
    <div className="mx-2 p-2" style={styles.card}>
      <div className="d-flex justify-content-between">
        <div>
          <div className="size-08">{name}</div>
          <div className="size-09 text-center bold">{quantity}</div>
        </div>
        <div
          className="d-flex justify-content-center align-items-center"
          style={styles.imageWrapper}
        >
          <Image src={boxImage} style={{ width: "30px" }} />
        </div>
      </div>
      <div className="d-flex size-06 mt-2" style={{ color: "#3e3e3e" }}>
        <div className="me-3">Ancho: {width / 100} cm</div>
        <div>Altura: {height / 100} cm</div>
      </div>
    </div>
  );
};

export default Card;
