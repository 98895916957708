import { Provider } from "react-redux";
import store from "./redux/store";
import RouterWrapper from "./routers/RouterWrapper";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/_global.scss";

const App = () => {
  return (
    <Provider store={store}>
      <ToastContainer />
      <RouterWrapper />
    </Provider>
  );
};

export default App;
