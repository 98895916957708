import Image from "../../../../components/Image";
import Header from "../../components/Header";
import checked from "../../../../assets/svg/checked.svg";
import { useNavigate } from "react-router-dom";
const Success = () => {
  const navigate = useNavigate();

  return (
    <div className="container-fluid h-100">
      <Header />
      <div className="content">
        <div className="row justify-content-center mt-5">
          <Image src={checked} style={{ width: "250px" }} />
        </div>
        <div className="row mt-5" style={{color: '#000'}}>
          <div
            className="col bold text-center"
            style={{ textTransform: "uppercase", fontSize: "3rem" }}
          >
            transacción completada <br />
            con éxito
          </div>
        </div>

        <div className="row mt-5">
          <div className="col text-center">
            <button
              className="px-4 py-3 border-0 main-button bold"
              onClick={() => navigate("/")}
              style={{ textTransform: "uppercase" }}
            >
              Volver al home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
