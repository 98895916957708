/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Button, SelectPicker } from "rsuite";
import MasterKeyAuth from "../../../components/MasterKeyAuth";
import { _handleError } from "../../../helpers/errors";
import { _pushToastMessage } from "../../../helpers/messages";
import { set_loader } from "../../../redux/actions/loader";
import { reload_modules, set_modules } from "../../../redux/actions/modules";
import { loader } from "../../../redux/reducers";
import TerminalService from "../../../services/terminal.service";
import DashboardTemplate from "../DashboardTemplate";

const DashboardModules = () => {
  const [masterKeyModal, setMasterKeyModal] = useState(false);
  const [modulesList, setModuleList] = useState<any>([]);
  const [moduleSelected, setModuleSelected] = useState<any>("");
  const { modules, session } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();

  const Terminal = new TerminalService();

  useEffect(() => {
    const m: any = modules.modules_list.map((module: any) => {
      return {
        value: module.slug,
        label: module.name,
      };
    });
    setModuleList(m);
    setModuleSelected(modules.module_selected);
  }, [modules]);

  const _handleChangeModule = async (payload: any) => {
    try {
      if (payload.status === "error") {
        throw new Error("Master key invalida.");
      }

      dispatch(set_loader({ ...loader, is_loading: true }));
      await Terminal.setModule({
        id: session?.profile?.id,
        module: moduleSelected,
      });
      dispatch(set_modules({ ...modules, module_selected: moduleSelected }));
      _pushToastMessage({
        text: "Cambiando modulo... Aguarde el sistema reiniciar.",
        header: "Info",
        type: "info",
      });
      setMasterKeyModal(false);
      setTimeout(() => {
        dispatch(set_loader({ ...loader, is_loading: false }));
        window.location.href = "/";
      }, 3000);
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
      _handleError(e, e.message);
    }
  };

  const _reloadModules = () => {
    dispatch(reload_modules());
  }

  return (
    <DashboardTemplate>
      <MasterKeyAuth
        open={masterKeyModal}
        title="Informe su master key"
        handleClose={() => setMasterKeyModal(false)}
        handleConfirm={(payload: any) => _handleChangeModule(payload)}
        description="El usuario {USER} cambió el modulo del terminal {TERMINAL} al dia {DATETIME} con la key {MASTERKEY}"
        action="CHANGE_MODULE"
      />

      <div className="row px-4 py-2 mx-0 mb-4">
        <div
          className="col-12 my-2 pb-2 size-12"
          style={{ borderBottom: "2px solid #afafaf", fontWeight: "bold" }}
        >
          Modulos
        </div>
      </div>

      <div className="row background-color-white shadow-sm px-3 py-5 mx-3 mt-3 rounded">
        <div className="col-12 px-5 size-09 mb-1" style={{ fontWeight: "500" }}>
          Selecciona un modulo
        </div>
        <div className="col-12 px-5 ">
          <SelectPicker
            data={modulesList}
            value={moduleSelected}
            className="w-100"
            placeholder="Seleciona un modulo"
            onChange={(value: any) => setModuleSelected(value)}
            locale={{ searchPlaceholder: "Buscar" }}
          />
        </div>

        <div className="col-12 text-end px-5 mt-5">
          <Button onClick={_reloadModules} className="me-4" appearance="primary">
            Recargar Modulos
          </Button>
          <Button onClick={() => setMasterKeyModal(true)} appearance="primary">
            Guardar
          </Button>
        </div>
      </div>
    </DashboardTemplate>
  );
};

export default DashboardModules;
