export const TableHeader = [
  {
    label: "Id Orden",
    key: "id",
    align: "center",
    alignFlex: "center",
    flexGrow: 1,
  },
  {
    label: "Nombre",
    key: "name",
    align: "left",
    alignFlex: "flex-start",
    flexGrow: 1,
  },
  {
    label: "Cantidad Cajas",
    key: "quantity_box",
    align: "center",
    alignFlex: "center",
    flexGrow: 1,
  },
];
