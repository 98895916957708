/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import BackgroundHome from "../../components/BackgroundHome";
import { ReactComponent as Delivery } from "../../../../assets/svg/package-box.svg";
import { ReactComponent as Pickup } from "../../../../assets/svg/package.svg";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { set_loader } from "../../../../redux/actions/loader";

const Home = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch()
  useEffect(() => {
    const timeout: any = setTimeout(() => {
      dispatch(set_loader({ is_loading: false }))
      console.log('CLOSE LOADER')
    }, 5000)

    return () => {
      clearInterval(timeout)
      console.log('CLEAR INTERVAL')
    }
  }, [])
  
  return (
    <div className="container-fluid h-100">
      <Header />
      <BackgroundHome />

      <div className="row justify-content-end align-items-center h-100 pe-4">

        <div className="d-flex flex-column align-items-center w-auto mx-5" style={{ marginBottom: '170px' }} onClick={() => navigate('/delivery')}>
          <div className="btn-circle-unimarc">
            <Delivery />
          </div>
          <span className="size-15">Carga un</span>
          <span className="size-13 bold">pedido aquí</span>
        </div>

        <div className="d-flex flex-column align-items-center w-auto mx-5" style={{ marginBottom: '170px' }} onClick={() => navigate('/pickup')}>
          <div className="btn-circle-unimarc">
            <Pickup />
          </div>
          <span className="size-15 bold">Retira su</span>
          <span className="size-13 ">pedido aquí</span>
        </div>

      </div>
    </div>
  );
};

export default Home;
