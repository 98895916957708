import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { set_session } from "../../../../redux/actions/session";
import ButtonLoad from "../../../../assets/svg/lider_button.svg";
import Logo from "../../../../components/Logo";
import "./component.header.scss";

const Header = () => {
  const { session } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const _handleSigIn = () => {
    dispatch(set_session({ ...session, sign_in_component: true }));
  };
  const navigate: any = useNavigate();
  return (
    <div className="row component-header mx-5 mt-4">
      <div className="col">
        <button className="btn" onClick={() => navigate("/delivery")}>
          <img src={ButtonLoad} alt="btn" style={{ width: "170px" }} />
        </button>
      </div>
      <div className="col d-flex justify-content-end">
        <div style={{ width: "170px" }}>
          <button className="btn m-0 p-0" onClick={_handleSigIn}>
            <Logo />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
