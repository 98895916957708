import api from "./api";
import store from "../redux/store";

class DeliveryUserService {
  constructor() {
    const { session } = store.getState();
    api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${session.security_token}`;
  }

  list_users = async (payload: any) => {
    try {
      const response = await api.get(
        `/terminal/delivery-users/users/${payload}`,
        payload
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  list_users_pickup_box = async (payload: any) => {
    try {
      const response = await api.get(
        `/terminal/delivery-users/pickup-box/users/${payload}`,
        payload
      );
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default DeliveryUserService;
