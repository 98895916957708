import api from "./api_locker";

class DoorLockerService {
  openDoor = async (payload: any) => {
    if (process.env.REACT_APP_ENVIROMENT === "DEVELOP") {
      return {
        data: {
          status: "success",
        },
      };
    }
    try {
      const response = await api.post(`/open-door`, payload);
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

export default DoorLockerService;
